import CacheCleaner from "./CacheCleaner"
import HorseCleaner from "./HorseCleaner"

export default class HorseActesCleaner extends CacheCleaner {
	async onMutation(actes_ids) {
		await this.init()

		// Vider le cache de tous les chevaux avec ces résidences
		return this.db().t('horse_actes')
		.then(table => {
			return table.where(':id').anyOf(actes_ids).toArray()
		})
		.then(actes => {
			return Array.from(new Set(actes.map(r => r.actes_horse)))
		})
		.then(horse_ids => {
			return this.onMutationHorse(horse_ids)
		})
	}

	// Horse est après parce qu'on a bien update une résidence
	// mais on donne des horse_ids en paramètre
	async onMutationHorse(horse_ids) {
		await HorseCleaner.inst().onMutation(horse_ids, ['horse_last_vermifuge_date'])
		await HorseCleaner.inst().onMutation(horse_ids, ['horse_last_vermifuge_label'])
		await HorseCleaner.inst().onMutation(horse_ids, ['horse_last_repro_act'])
		await HorseCleaner.inst().onMutation(horse_ids, ['horse_last_echographie_label'])
	}
}
