<template>
    <b-modal :ref="uniqueModalRef">
        <template slot="modal-header" slot-scope="{ close }">
            <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
            <h2 class="mx-auto my-auto">{{ $t('sanitaire.demande_analyse') }}</h2>
        </template>
        <template slot="default">
            <div class="form-group">
            	<label for="demandeur" class="col-form-label">{{ $t("sanitaire.tier_demandeur") }}<span> *</span></label>
	        	<e-select
	                v-model="demandeurSelected"
	                id="demandeur"
	                track-by="tiers_rs"
	                label="tiers_rs"
	                class="primary"
	                :options="entities"
	                :searchable="true"
	                :show-labels="false"
	                :allow-empty="false"
	            >
	            	<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }}</template>
	                <template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
	                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
	            </e-select>
	        </div>
            <div class="form-group">
            	<label for="facture" class="col-form-label">{{ $t("sanitaire.tier_facture") }}<span> *</span></label>
	        	<e-select
	                v-model="factureSelected"
	                id="facture"
	                track-by="tiers_rs"
	                label="tiers_rs"
	                class="primary"
	                :options="entities_facture"
	                :searchable="true"
	                :show-labels="false"
	                :allow-empty="false"
	            >
	            	<template slot="option" slot-scope="{ option }">{{ option.tiers_rs }}</template>
	                <template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }}</template>
	                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
	            </e-select>
            </div>
            <div class="form-group">
                <label for="contact" class="col-form-label">{{ $t('acte.choix_contact') }}</label>
                <e-select
                    v-model="contactSelected"
                    id="contact"
                    track-by="contact_id"
                    :placeholder="$t('acte.selectionnez_contact')"
                    :selectedLabel="$t('global.selected_label')"
                    :options="contacts"
                    :searchable="true"
                    :allow-empty="true"
                    :show-labels="false"
                    :group-select="false"
                >
                    <template slot="option" slot-scope="{ option }">{{ option.contact_civility }} {{ option.contact_firstname }} {{ option.contact_lastname }}</template>
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.contact_civility }} {{ option.contact_firstname }} {{ option.contact_lastname }}</template>
                    <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                </e-select>
            </div>
        </template>
        <template slot="modal-footer" slot-scope="{ ok, cancel}" class="justify-content-center">
            <b-button variant="primary" @click="handleSubmit">
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse class="mr-1"/>
                <font-awesome-icon v-else :icon="['fal', 'file-medical']" class="mr-1"/>{{ $t('global.valider') }}
            </b-button>
            <b-button @click="cancel()">
                <font-awesome-icon :icon="['fal', 'times']" class="mr-1"/> {{ $t('global.annuler') }}
            </b-button>
        </template>
    </b-modal>
</template>


<script type="text/javascript">
    import Tiers from "@/mixins/Tiers.js"
    import Contact from "@/mixins/Contact.js"
    import Actes from "@/mixins/Actes.js"
    import ResultatAnalyse from "@/mixins/ResultatAnalyse.js"
    import _uniqBy from 'lodash/uniqBy'
    import _mapValues from 'lodash/mapValues'

	export default {
        props: {
	        callback_validation : Function
		},
		mixins: [Tiers, Contact, Actes, ResultatAnalyse],
        data() {
            return {
            	analyse: {},
            	demandeurSelected: null,
                factureSelected: null,
            	entities: [],
                entities_facture: [],
                processing: false,
                contactSelected: null,
                contacts: []
            }
        },
        methods: {
            openModal(analyse) {
            	this.analyse = analyse
                this.form_message = ''
				this.$refs[this.uniqueModalRef].show()
                this.loadEntity()
            },

            async loadEntity() {
				this.entities = await this.loadTiersEntity()
                this.entities_facture = [{tiers_rs: this.getTrad('sanitaire.tiers_proprio'), tiers_id: -1}, ... this.entities]
                this.contacts = await this.getAllContact(true)

                if(this.analyse.analyse_demandeur) {
                    this.demandeurSelected = this.entities.find(tier => tier.tiers_id == this.analyse.analyse_demandeur.tiers_id)
                }
                if(this.analyse.analyse_invoicer) {
                    this.factureSelected = this.entities_facture.find(tier => tier.tiers_id == this.analyse.analyse_invoicer.tiers_id)
                }
                else {
                    this.factureSelected = this.entities_facture.find(tier => tier.tiers_id == -1)
                }
                if(this.analyse.analyse_operateur) {
                    this.contactSelected = this.contacts.find(contact => contact.contact_id == this.analyse.analyse_operateur.contact_id)
                }
            },

            closeModal() {
				this.$refs[this.uniqueModalRef].hide()
            },

            async handleSubmit() {
                this.processing = true

                let promises = []
                promises.push(this.updateOperateurActes(this.analyse.analyse_actes, this.contactSelected.contact_id))
                promises.push(this.editAnalyse(this.analyse.analyse_id, this.demandeurSelected.tiers_id, this.factureSelected.tiers_id, this.analyse.analyse_horse.horse_nom))

                await Promise.all(promises)
                this.processing = false
				this.closeModal()
            }
        },
        computed: {
            uniqueModalRef() {
                return 'modal-edit-analyse-'+this._uid
            },
        },
	}
</script>
