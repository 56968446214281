import Constants from '../../config.local.js'
import Common from '@/assets/js/common'

var Temperature = {
    methods: {
		addTemperature: async function(horse_id, params) {
            const url = this.constructRoute(Constants.HORSE_TEMPERATURE, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("TemperatureMixin::saveTemperature", url, params, false)
        },

        getTemperatureByHorse: async function(horse_id) {
            const url = this.constructRoute(Constants.HORSE_TEMPERATURE, { horse_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TemperatureMixin::getTemperatureByHorse", url)
            .catch(error => {
                console.error("TemperatureMixin::getTemperatureByHorse => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        getTemperatureById: async function(horse_id, temperature_id) {
            const url = this.constructRoute(Constants.HORSE_TEMPERATURE_BY_ID, { horse_id, temperature_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("TemperatureMixin::getTemperatureById", url)
            .catch(error => {
                console.error("ContractMixin::getTemperatureById => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        updateTemperature: async function(horse_id, temperature_id, params) {
            const url = this.constructRoute(Constants.HORSE_TEMPERATURE_BY_ID, { horse_id, temperature_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("TemperatureMixin::saveTemperature", url, params, false)
        },


        deleteTemperature: async function(horse_id, temperature_id) {
            const url = this.constructRoute(Constants.HORSE_TEMPERATURE_BY_ID, { horse_id, temperature_id }) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.$request.request_delete_api("TemperatureMixin::deleteTemperature", url)
        },

        getTemperatureOnDuration: async function(horse_id, start, end) {
            const url = this.constructRoute(Constants.HORSE_TEMPERATURE_ON_DURATION, { horse_id, start, end }) +'?licence_key='+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("TemperatureMixin::getTemperatureOnDuration", url)
            .catch(error => {
                console.error("TemperatureMixin::getTemperatureOnDuration => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },
    }
}

export default Temperature
