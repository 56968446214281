import Constants from '../../config.local.js'

export default {
	methods: {
		getCollecteEmbryons: function(actes_id) {
			const url = this.constructRoute(Constants.EMBRYONS_COLLECTE_GET_URL, {actes_id: actes_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("EmbryonMixin::getCollecteEmbryons", url)
			.then(response => {
				return response.retour
			})
			.catch(error => {
				console.error("EmbryonMixin::getCollecteEmbryons => ERROR", error)
				return []
			})
		},

		getSeasonEmbryons: function(season_id) {
			const url = this.constructRoute(Constants.SEASON_EMBRYONS_URL, { season_id }) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("EmbryonMixin::getSeasonEmbryons", url)
			.then(response => {
				return response.retour
			})
		},

		getAvailableEmbryons: function(actes_id = null) {
			let url = Constants.EMBRYONS_AVAILABLE_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			if(actes_id) {
				url += `&transplantation_id=${actes_id}`
			}

			return this.$request.request_get_api("EmbryonMixin::getHorseAvailableEmbryons", url)
			.then(response => {
				return response.retour
			})
		},

		upsertEmbryons: function(actes_id, embryons) {
			const url = Constants.EMBRYONS_UPSERT_URL + '?licence_key=' + Constants.USER_LICENCE_KEY
			const params = {
				actes_id: this.$sync.replaceWithReplicated('horse_actes', actes_id),
				embryons
			}

			return this.$request.request_post_api("EmbryonMixin::upsertEmbryons", url, params, false)
		},

		addEmbryon: function(params) {
			const url = Constants.EMBRYONS_UPSERT_COUPLE_URL + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("EmbryonMixin::addEmbryon", url, params, false)
		},

		editEmbryon: async function(embryon_id, embryon_detruit, embryon_commentaire, embryon_taille, embryon_stade, embryon_congele) {
			const params = {
				embryon_detruit: embryon_detruit,
				embryon_commentaire: embryon_commentaire,
				embryon_taille: embryon_taille,
				embryon_stade: embryon_stade,
				embryon_congele: embryon_congele
			}

			const url = this.constructRoute(Constants.EMBRYONS_EDIT_URL, { embryon_id }) + "?licence_key=" + Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("EmbryonMixin::editEmbryons", url, params, false)
			.catch(error => {
				console.error("EmbryonMixin::editEmbryons => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})
			return result
		},
		
		getSeasonEmbryonsMare: function(season_id, horse_id) {
			const params = {
				horse_id: horse_id
			}

			const url = this.constructRoute(Constants.SEASON_EMBRYONS_MARE_URL, { season_id }) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("EmbryonMixin::getSeasonEmbryons", url, params, false)
			.then(response => {
				return response.retour
			})
		},

		deleteEmbryon: function(embryon_id) {
			const url = this.constructRoute(Constants.EMBRYONS_ID_URL, { embryon_id }) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_delete_api("EmbryonMixin::deleteEmbryon", url)
			.then(response => {
				return response.retour
			})
		},

		expeditionEmbryon: function(embryons, tiers_id) {
			const params = {
				embryons_ids: embryons.map(embryon => embryon.embryon_id)
			}
			const url = this.constructRoute(Constants.EMBRYONS_EXPEDITION, { tiers_id }) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("EmbryonMixin::expeditionEmbryon", url, params, false)
			.then(response => {
				return response.retour
			})
		},

		freeEmbryonFromActe: function(acte_id) {
			const url = this.constructRoute(Constants.FREE_EMBRYON_URL, { acte_id }) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("EmbryonMixin::freeEmbryonFromActe", url)
			.then(response => {
				return response.retour
			})
		}
	}
}
