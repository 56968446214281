import Transformer from './Transformer'
import ActeTransformer from './ActeTransformer'
import QuestionTransformer from './QuestionTransformer'
import ReponseTransformer from './ReponseTransformer'
import Vue from 'vue'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export class SanitaireTransformer extends Transformer {

    table = 'horse_actes_result'

    async question(item) {
        return this.db().t('question')
        .then(table => {
            return table.where({
                question_fk: parseInt(item.resulttype_id),
                question_type: 'horse_actes_result_type'
            }).toArray()
        })
    }

    getResponses(item) {
        return this.db().t('reponse')
        .then(table => {
            return table.where({
                reponse_fk: parseInt(item.result_id),
                reponse_type: 'horse_actes_result'
            })
            .transform(new ReponseTransformer('withQuestion'))
        })
        .then(async result => {
            return result
        })
    }

    fetchHeavy(col) {
        return col.with({
            'acte'            : 'result_acte',
        })
    }

    async transformHeavy (sanitaire_result) {
        const acte = await ActeTransformer.process(sanitaire_result.acte, 'withReponseNotFormatted')
        const laboratoire = acte.reponse.find(rep => rep.reponse_question_code == 'Laboratoire')
        const reponses = await this.getResponses(sanitaire_result)
        const result = reponses.find(rep => rep.reponse_question_code == "Résultat")
        const inter = reponses.find(rep => rep.reponse_question_code == "Interprétation")

        return {
            'result_id'			: sanitaire_result.result_id,
            'result_type'		: sanitaire_result.result_type,
            'result_analyse'	: Vue.i18n.translate('reponses.' + sanitaire_result.result_analyse).includes('reponses.') ? sanitaire_result.result_analyse : Vue.i18n.translate('reponses.' + sanitaire_result.result_analyse),
            'result_prelevement': sanitaire_result.result_prelevement,
            'laboratoire'       : laboratoire ? laboratoire.reponse_formatted_data : '',
            'result'            : (result ? result.reponse_formatted_data : '') + (inter ? " (" + inter.reponse_formatted_data + ")" : ""),
            'acte'				: acte
        }
    }

    fetchTypeWithQuestion (col) {
        this.additionalColumns({
            question: this.question
        })
        
        return col
    }

    async transformTypeWithQuestion (result_type) {
        return {
            'resulttype_id'         : result_type.resulttype_id,
            'resulttype_actetype'   : result_type.resulttype_actetype,
            'question'              : result_type.question,
        }
    }
}

export default SanitaireTransformer